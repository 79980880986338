import clsx from "clsx";
import type { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Combines twMerge and clsx to allow for overriding tw classes and object synyax via clsx.
 * Pulled from https://github.com/shadcn-ui/ui/blob/main/apps/www/lib/utils.ts
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
